import {FormControl,} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {isFunction} from "../utils";
import {useTranslation} from "react-i18next";
import {CompTooltipIcon} from "./TooltipIcon";

const CompMultiselectDropdown = (props) => {
    let {error} = props
    const {
        rows = [],
        selectedRows,
        keyColumn,
        valueFunc = (row) => {
            return row.toString()
        },
        onSelect,
        disabled,
        label = "",
        parentsUnselectable = false
    } = props;

    const {t} = useTranslation('translation');

    if(error !== undefined && error !== null) {
        error = t("errors."+error);
    } else {
        error=null;

    }
    const [isOpen, setOpen] = React.useState(false);

    const handleToggle = (event, row, index) => {
        event.stopPropagation();
        event.preventDefault();

        if (disabled) return;

        const i = selectedRows.indexOf(row[keyColumn])
        const isAddAction = i < 0

        if (isFunction(onSelect)) {
            onSelect(row, isAddAction, index, i)
        }
    }

    const getSelectedRows = () => {
        return rows.filter(item => {
            return isSelected(item[keyColumn])
        });
    }
    const isSelected = value => {
        if(selectedRows === undefined || selectedRows === null) {
            return false;
        }
        return selectedRows.indexOf(value) >= 0
    }

    const toggleDropdown = event => {
        event.stopPropagation();
        event.preventDefault();
        if (disabled) return;

        setOpen(!isOpen);
    }

    let searchOverlay = (<div/>);
    if(isOpen) {
        searchOverlay = (<div className={'searchOverlay'}
                              onClick={toggleDropdown}/>);
    }
    
    return (
        <FormControl
            className={`comp-multiselect-dropdown-container 
            ${props.className ? 'container-' + props.className : ''} 
            ${error ? 'error' : ''} 
            ${disabled ? 'disabled' : ''}`}>
            <div className={'label'}>{label}</div>
            <div className={'comp-multiselect-dropdown'}>
                <a href="?lnk=1" className={'input-link'} onClick={event => {
                    toggleDropdown(event)
                }}>
                    <ul className={`selected-list ${isOpen ? 'open' : 'closed'}`}>
                        {getSelectedRows().map((row, index) => (
                            <li className={'item'} key={`selected-list-item-${index}`}>
                                <div>{valueFunc(row).trim()}
                                    <div className='closeButton'
                                         onClick={(event) => {
                                             handleToggle(event, row, index)
                                         }}/>
                                </div>
                            </li>
                        ))}
                        <div className={'clear'}/>
                    </ul>
                </a>
                <div className={`dropdown-menu ${isOpen ? 'open' : 'closed'}`}>
                    <ul className={'menu-list'}>
                        {rows.map((row, index) => (
                            <li key={`menu-list-item-${index}`}
                                className={`item item-${index} ${isSelected(row[keyColumn]) ? 'selected' : ''}`}

                            ><div className="toolTip-click">
                            {parentsUnselectable && row.disabled ? valueFunc(row) : <a href="?lnk=1" onClick={event => {
                                    handleToggle(event, row, index)
                                }}>{valueFunc(row)}
                            </a>}  
                                {row.descriptionFi && t('report.current_language') === "FINNISH" && <CompTooltipIcon ignore={true}>{row.descriptionFi}</CompTooltipIcon>}
                                {row.descriptionSv && t('report.current_language') !== "FINNISH" && <CompTooltipIcon ignore={true}>{row.descriptionSv}</CompTooltipIcon>}
                            </div></li>
                        ))}
                    </ul>
                </div>
            </div>
            {searchOverlay}
            <div className={`error-label ${props.className ? 'error-' + props.className : ''}`}>{error}</div>
        </FormControl>
    );
}

CompMultiselectDropdown.propTypes = {
    label: PropTypes.string,
    rows: PropTypes.array,
    selectedRows: PropTypes.array,
    error: PropTypes.string
};

export {CompMultiselectDropdown};
